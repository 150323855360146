.breadcrumb {
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb li {
  display: inline;
  font-size: 14px;
}

.breadcrumb li a {
  color: #6D7B8D;
  text-decoration: none;
}

.breadcrumb li a:hover {
  color: #50728A;
}

.breadcrumb li a:active {
  color: #253545;
}

.breadcrumb li a:visited {
  color: #8B9AA3;
}

.breadcrumb li::after {
  content: ' > ';
  color: #A9A9A9;
}

.breadcrumb li:last-child::after {
  content: '';
}
