.arrow {
	align-items: center;
	cursor: pointer;
	fill: #e0e0e0;
	position: absolute;
	display: flex;
	height: calc(100% - 20px);
	top: calc(50% - 10px);
	transform: translateY(-50%);
	transition: all 0.3s ease-out;
	width: 20px;
	z-index: 10;
	box-shadow: inset -20px 0 20px -20px rgba(0,0,0,0.5);
}

.has-no-scrollbar .arrow {
	height: calc(100% - 8px);
	top: calc(50% - 4px);
}

.arrow:focus {
	outline: none;
}

.arrow-left {
	left: 0;
	padding-left: 5px;
	padding-right: 0px;
	box-shadow: inset 20px 0 20px -20px rgba(0,0,0,0.5);
}

.arrow-right {
	left: auto;
	padding-left: 0px;
	padding-right: 5px;
	right: 0;
}

.arrow svg {
	flex: 0 0 40px;
	height: 55px;
	padding: 10px 0;
	width: 40px;
}

.arrow-left svg {
	transform: translateX(-10px);
}

.arrow-right svg {
	transform: translateX(-20px);
}

.arrow-disabled {
	fill: rgba(255, 255, 255, 0.5);
	box-shadow: none;
}

.dots-container {
	position: absolute;
	display: flex;
	justify-content: center;
	bottom: 24px;
	width: 100%;
}

.dots-container.has-no-scrollbar {
	bottom: 12px;
}

.dot {
	height: 7px;
	width: 7px;
	margin: 0 5px;
	padding: 5px;
	background-color: #bbb;
	border-radius: 5px;
	display: block;
	transition: all 0.3s ease-out;
}

.dot:focus {
	outline: 1px solid #000;
}

.dot-active {
	background-color: #717171;
	width: 20px;
}

@media (min-width: 1024px) {
	.arrow {
		box-shadow: inset -20px 0 20px -20px rgba(0,0,0,0.25);
		border-right: 1px solid #c6c6c6;
	}

	.arrow-left {
		box-shadow: inset 20px 0 20px -20px rgba(0,0,0,0.25);
		border-right: none;
		border-left: 1px solid #c6c6c6;
	}

	.arrow.arrow-disabled {
		box-shadow: none;
	}

	.dots-container.hide-dots-mobile {
		display: none;
	}
}

