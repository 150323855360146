.popup {
  --animate-duration: 0.25s;

  height: 100dvh;
  max-height: 95dvh;
  width: 100vw;
  animation-delay: 0.1s;
  z-index: 100;
}

.review-grid-photo {
  @apply rounded-md;
  background: #c4c4c4;
  aspect-ratio: 1;
  flex: 0 0 calc(50% - 4px);
  position: relative;
  width: calc(50% - 4px);
}

.arrow-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: absolute;
  top: 60px;
  width: 55px;
  z-index: 5;
  transition: all 0.42s ease-in-out;
}

.arrow-fade-out {
  transition: opacity 0.25s ease-in-out;
  opacity: 0 !important;
  pointer-events: none;
}

.arrow-left {
  left: 0;
  padding-left: 5px;
}

.arrow-right {
  left: auto;
  padding-right: 5px;
  right: 5px;
}

.arrow-disabled {
  fill: rgba(255, 255, 255, 0.5);
}

.arrow {
  align-items: center;
  aspect-ratio: 1;
  background: #8c8c8c;
  border-radius: 50%;
  display: flex;
  fill: #fff;
  justify-content: center;
  padding: 4px;
  width: 45px;
  transform: translateY(-60px);
}

.arrow svg {
  height: 22px;
  width: 22px;
}

.arrow-left svg {
  transform: translateX(-2px);
}

.arrow-right svg {
  transform: translateX(0px);
}

.all-photos {
  height: 100%;
}

@keyframes fadeInBg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-bg {
  animation: fadeInBg 0.25s ease-in-out;
  background-color: rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
  .popup-delay-mobile {
    animation-delay: 0.6s;
  }
}

@media (min-width: 768px) and (min-height: 425px) {
  .popup {
    height: 100%;
    max-height: 640px;
    max-width: 1000px;
    width: calc(100% - 120px);
  }

  .popup.popup-individual {
    max-height: 535px;
  }

  .review-grid-photo {
    flex: 0 0 calc(33.333333% - 6px);
    width: calc(33.333333% - 6px);
  }

  .arrow-wrapper {
    top: 0;
  }

  .arrow {
    background: #222;
    transform: none;
  }

  .arrow-left-v2 {
    left: 0;
  }

  .arrow-right-v2 {
    right: 0;
  }
}

@media (min-width: 992px) {
  .review-grid-photo {
    flex: 0 0 calc(33.333333% - 6px);
    width: calc(33.333333% - 6px);
  }
}

.review-grid-photo:hover {
  cursor: pointer;
}

@media (min-width: 1200px) {
  .popup {
    height: 95dvh;
    max-height: 1010px;
    max-width: 1100px;
    width: calc(100% - 200px);
  }

  .popup.popup-individual {
    max-height: 950px;
  }

  .review-grid-photo {
    flex: 0 0 calc(25% - 8px);
    width: calc(25% - 8px);
  }

  .all-photos {
    height: calc(100% - 100px);
  }
}
