.nav-container {
  --bg-menu-color: rgb(49, 53, 57);

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-basis: 40%;
}

.menu-item {
  position: relative;
  text-align: left;
  background-color: var(--bg-menu-color); /* Define this variable in your CSS */
}

.group:hover .group-hover {
  display: block;
  pointer-events: auto;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: -0.25rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}

/* Submenu */
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 14rem; /* Equivalent of w-56 */
  border-radius: 0.375rem; /* Equivalent of rounded */
  background-color: var(--bg-menu-color); /* Define this variable in your CSS */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: none;
  pointer-events: none;
}

.submenu-item {
  padding: 0.5rem 1rem;
  font-size: 0.875rem; /* Equivalent of text-sm */
  font-family: 'Oswald'; /* Ensure the font is imported */
  letter-spacing: .1em;
  color: white;
}

.submenu-item:hover {
  text-decoration: underline;
  transition: all 0.3s ease;
}

.main-nav-item:focus,
.main-nav-item:focus-visible {
  outline: 2px solid #fff;
  outline-offset: -2px;
}

.main-menu-item:hover .main-submenu {
  display: flex;
  pointer-events: auto;
}

.main-nav-items {
  transform-origin: top right;
  left: 0;
  display: none;
  pointer-events: none;
  position: absolute;
  width: 14rem;
  border-radius: 0.25rem;
  z-index: 20;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid black;
  outline: none;
  background-color: var(--bg-menu-color);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

}
