.bg-menu {
  background: rgb(49, 53, 57);
}

.custom-transition {
  transition-duration: 0.3s;
}

.custom-margin-top {
  margin-top: 5px;
}

.bg-primary {
  background-color: #26292b;
}

.bg-mobile-header {
  background-color: #26292b;
}

.bg-header {
  background-color: #26292b;
}

.letter-spacing-2x {
  letter-spacing: 2px;
}

.menu-bg-color {
  /*background-color: #101111;*/
  background-color: rgba(27, 31, 35, 0.9);
}

.w-full-screen {
  width: 100vw;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

[role='tab'].active {
  /*@apply text-black border-black hover:border-black dark:hover:text-black dark:hover:border-black;*/
  @apply text-black border-black;
}

[role='tab'].active-mobile {
  @apply text-white border-white;
}

@media (max-width: 640px) {
  .mobile-none {
    display: none;
  }

  .section-title {
    font-size: 22px;
  }
}

@media (min-width: 640px) {
  .table-none {
    display: none;
  }
}

.bg-featured-collection {
  background-color: #484b4c;
}

.scrollbar-hide {
  -ms-overflow-style: none;

  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.rotate-animation {
  animation: rotation 1s 5s ease-in-out;
}

/* hero */
/*noinspection CssInvalidPropertyValue*/
.gradient-text {
  background: linear-gradient(to top, black -55%, rgba(0, 0, 0, 0) 55%);
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

/* animation home */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInSuccess {
  opacity: 0;
  animation: fadeIn ease 1s;
  animation-fill-mode: forwards;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn ease 0.5s;
  animation-fill-mode: forwards;
}

.fadeInTwo {
  opacity: 0;
  animation: fadeIn ease 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}

.fadeInTwoDesktop {
  opacity: 0;
  animation: fadeIn ease 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.fadeInTwoDesktop {
  opacity: 0;
  animation: fadeIn ease 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.fadeInHeader {
  opacity: 0;
  animation: fadeIn ease 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-150%, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInLeftOne {
  transform: translate3d(-150%, 0, 0);
  animation: slideInLeft ease 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.slideInRightDesktop1 {
  transform: translate3d(150%, 0, 0);
  animation: slideInRight ease 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.slideInRightDesktop2 {
  transform: translate3d(150%, 0, 0);
  animation: slideInRight ease 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.slideInRightDesktop3 {
  transform: translate3d(150%, 0, 0);
  animation: slideInRight ease 0.5s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -50px, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInDown {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  animation: slideInDown ease 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 50px, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInUp {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  animation: slideInUp ease 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slideInUpSmall {
  from {
    transform: translate3d(0, 10px, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInUpSmall {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  animation: slideInUpSmall ease 0.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes pulse-animation {
  0% {
    opacity: .5;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.pulse-animation {
  animation: pulse-animation 2s linear infinite;
}

/* home category */
.center .slick-center img {
  transform: scale(1.25);
  box-shadow: 7px 10px 8px rgba(0, 0, 0, 0.4);
  opacity: 1;
  z-index: 2;
}

.center .center-wrapper {
  padding: 35px 2%;
  padding-bottom: 50px;
}

.center img {
  transition: all 0.2s;
  border-radius: 9px;
  position: relative;
  z-index: 1;
  opacity: 0.4;
}

.center *:focus-visible {
  outline: none;
}

.slideInLeftSubwoofer {
  transform: translate3d(-100%, 0, 0);
  animation: slideInLeft ease 0.3s;
  animation-fill-mode: forwards;
}

.slideInRightSubwoofer {
  transform: translate3d(100%, 0, 0);
  animation: slideInRight ease 0.3s;
  animation-fill-mode: forwards;
}

.shadow-primary {
  box-shadow: 7px 10px 8px rgba(0, 0, 0, 0.4);
}

/* home collection */
.center-collection .slick-center .card-collection {
  box-shadow: 7px 10px 8px rgba(0, 0, 0, 0.4);
  opacity: 1;
  z-index: 2;
}

.center-collection .card-collection {
  transition: all 0.2s;
  position: relative;
  z-index: 1;
  opacity: 0.4;
}

.divider-gradient {
  background: linear-gradient(90deg, #c4c4c4 0%, rgba(196, 196, 196, 0) 100%);
  height: 1px;
  width: 100%;
  border-radius: 10px;
}

.home-input {
  background: #fff;
  border-radius: 4px;
  padding: 8px 12px;
  width: 100%;
}

.home-input::placeholder {
  color: #4d4d4d;
}

.home-input:focus-visible {
  outline: none;
}

.home-checkbox {
  position: relative;
  cursor: pointer;
  height: 10px;
}

.home-checkbox:before {
  content: '';
  display: block;
  position: absolute;
  width: 13px;
  height: 14px;
  top: -3px;
  left: 0px;
  background-color: #cccccc;
  border-radius: 4px;
}

.home-checkbox:checked:before {
  content: '';
  display: block;
  position: absolute;
  width: 13px;
  height: 14px;
  top: -3px;
  left: 0px;
  background-color: #0000ff;
  border-radius: 4px;
}

.home-checkbox:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 4px;
}

/* product */
.center-product .slick-center .center-wrapper .slick-image-wrapper {
  transform: scale(1.25);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  opacity: 1;
  z-index: 2;
}

.center-product .center-wrapper {
  padding: 35px 2%;
  padding-bottom: 46px;
  padding-top: 30px;
}

.center-wrapper .slick-image-wrapper {
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 9px;
  position: relative;
  z-index: 1;
  margin-top: 12px;
  opacity: 0.4;
}

.center-product *:focus-visible {
  outline: none;
}

.center-quantity .slick-center p {
  transform: scale(1);
  opacity: 1;
  z-index: 2;
}

.center-quantity p {
  transition: all 0.2s;
  border-radius: 9px;
  position: relative;
  z-index: 1;
  opacity: 0.4;
  transform: scale(0.8);
}

.center-quantity *:focus-visible {
  outline: none;
}

.desc a {
  color: white;
}

.desc strong {
  color: white;
}

.product-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.collection-page-title-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.slick-dots {
  bottom: 0 !important;
}

.react-images__navigation {
  opacity: 1 !important;
}

.react-images__header {
  opacity: 1 !important;
}

.react-images__footer {
  opacity: 1 !important;
}

.react-images__navigation svg {
  color: black;
}

div.react-images__track {
  display: block !important;
}

/* https://stackoverflow.com/questions/3197601/white-space-at-bottom-of-anchor-tag */
a:has(> img) {
  font-size: 0;
}

.faq-answer p {
  margin-bottom: 1rem;
}

.faq-answer a {
  text-decoration: underline;
}

.checkout-form-field-box-styling {
  border: 1px solid rgb(136, 136, 136);
  margin-bottom: 16px;
  line-height: 2.8em;
  padding-left: 6px;
}

.job-detail p {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.product-subtitle {
  color: #626161;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.15px;
  font-size: 1rem;
}

.product-image-gallery-border {
  border: 0.25px solid rgb(194, 194, 194);
  display: block;
  box-sizing: border-box;
  margin-left: 8px;
  margin-right: 8px;
  /*box-shadow: 0.25px 0.5px 0.5px;*/
}

.spec-table-text {
  color: #fff;
}

.testimonials-slider .slick-track {
  display: flex;
  align-items: center;
}

.custom-transition {
  transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.battery-sku-hover-effect:hover > a:not(:hover) img {
  filter: grayscale(100%);
}

.battery-sku-hover-effect:hover > a:hover .battery-sku-title {
  text-decoration: underline;
}

@media (prefers-color-scheme: dark) {
  input[type='text'].no-darkmode {
    background-color: white;
    -webkit-appearance: none;
    appearance: none;
  }

  input[type='checkbox'].no-darkmode {
    background-color: white;
    border: 1px solid #333;
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 18px;
  }

  input[type='checkbox'].no-darkmode:checked {
    background-color: white;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23000" viewBox="0 0 512 512"><path d="M362.6 192.9 345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
  }
}

input[type='text'].donate-checkbox {
  background-color: white;
  -webkit-appearance: none;
  appearance: none;
}

input[type='checkbox'].donate-checkbox {
  background-color: white;
  border: 1px solid #333;
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
}

input[type='checkbox'].donate-checkbox:checked {
  background-color: white;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23000" viewBox="0 0 512 512"><path d="M362.6 192.9 345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px;
}
