.imageContainer {
  border: solid 1.5px silver;
  box-sizing: content-box;
  border-radius: 8px;
  background-clipping: content-box;
  position: relative;
}
.imageContainer img {
  border-radius: 10px;
}

.soldout {
  background-color: #000;
  box-shadow: -1px 7px 8px rgb(0 0 0 / 40%);
  color: #31cc00;
  border: 3px solid #31cc00;
  border-radius: 0.5rem;
  left: calc(50% - 2rem);
  letter-spacing: 0.1em;
  position: absolute;
  font-size: 1.25rem;
  font-family: 'Oswald', sans-serif;
  transform: rotate(40deg);
  transform-origin: 0 0;
  top: calc(50% - 5rem);
  text-transform: uppercase;
  padding: 0.2rem 1rem;
  white-space: nowrap;
}

@media (min-width: 768px) and (min-height: 425px) {
  .soldout {
    font-size: 2rem;
    top: calc(50% - 7rem);
  }
}
