.root {
  position: fixed;
  inset: 0;
  top: 0;
  z-index: 50;
  box-sizing: border-box;
  height: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.sidebar {
  @apply h-full flex flex-col text-base bg-accent-0 shadow-xl overflow-y-auto overflow-x-hidden;
  /*overscroll-behavior: none;*/
  -webkit-overflow-scrolling: touch !important;
}

.backdrop {
  @apply absolute inset-0 bg-black bg-opacity-40 duration-100 ease-linear;
  backdrop-filter: blur(0.8px);
  -webkit-backdrop-filter: blur(0.8px);
  z-index: 1;
}

.cart {
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  outline: 2px solid transparent;
  outline-offset: 2px;
  right: 0;
  top: 0;
  position: absolute;
  width: calc(100% - 6rem);
  z-index: 2;
}

@media (min-width: 768px) and (min-height: 425px) {
  .cart {
    width: auto;
  }
}


@media (min-width: 1200px) {
  .cart {
    top: var(--cart-top, 45px);
  }
}

