/* mobile only styles */
@media (max-width: 767px) {
    .img-sku-sk-batt20ah {
        transform: translateY(-5px)  scale(1.15);
    }

    .img-sku-sk-batt35ah {
        transform: scale(1.1);
    }

}

