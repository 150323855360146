.root {
  @apply relative h-full flex flex-col;
  border-radius: 5px;
  background: #fff;
}

.header {
  @apply sticky top-0 pl-4 py-4 pr-6
  flex items-center justify-between
  bg-accent-0 box-border w-full z-10;
  min-height: 66px;
  border-bottom: solid 1px silver;
}

.container {
  @apply flex flex-col flex-1 box-border;
}

@screen lg {
  .header {
    min-height: 74px;
  }
}
