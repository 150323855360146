.root {
	min-height: 100vh;
}

.root.empty {
	@apply bg-secondary text-secondary;
}

.lineItemsList {
	@apply sm:divide-y sm:divide-accent-2 border-accent-2;
}
