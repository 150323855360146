:root {
  --countdown-bg-color: rgba(0, 0, 0, 0.8);
  --countdown-shadow-color: rgba(0, 0, 0, 0.1);
}

.sale-countdown-timer {
  align-items: center;
  background-color: var(--countdown-bg-color);
  box-shadow: 0 4px 8px var(--countdown-shadow-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0;
}

.sale-countdown-timer .sale-label {
  font-size: 1.5rem;
  color: #fff;
  line-height: 1;
  margin-bottom: 1rem;
  font-weight: bold;
}

.sale-countdown-timer .countdown {
  font-size: 3rem;
  line-height: 0.9;
  letter-spacing: 1px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sale-countdown-timer .countdown.danger {
  color: rgb(255, 255, 17);
  font-weight: bold;
  text-shadow: 2px 2px 2px #000;
}

.sale-countdown-timer .countdown > span {
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1.6rem;
  margin-top: 2px;
}

.sale-countdown-timer .countdown-link {
  display: flex;
  align-items: center;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  color: #ffb409;
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  .sale-countdown-timer {
    padding: 0.05rem;
  }

  .sale-countdown-timer .sale-label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .sale-countdown-timer .countdown {
    font-size: 2rem;
    line-height: 0.8;
    padding: 2px;
  }

  .sale-countdown-timer .countdown > span {
    font-size: 1rem;
    line-height: 1.1rem;
    margin-top: 1px;
  }

  .sale-countdown-timer .countdown-link {
    font-size: 0.8rem;
  }

  .sale-countdown-timer .countdown-link > div:not(:last-child) {
    padding-right: 10px;
  }
}

@media screen and (min-width: 768px) {
  .sale-countdown-timer {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .sale-countdown-timer .sale-label {
    font-size: 2.5rem;
    margin-right: 1.5rem;
  }

  .sale-countdown-timer .countdown-link {
    font-size: 2.5rem;
  }

  .sale-countdown-timer .countdown-link > div:not(:last-child) {
    padding-right: 20px
  }

  .sale-countdown-timer .countdown-link > div > span {
    font-size: 1.2rem;
  }
}
