@font-face {
  font-family: 'Poppins Medium 500';
  src: url('/fonts/Poppins Medium 500.ttf') format('truetype');
  font-display: swap;
}

.addToCart_preorder {
  font-family: 'Poppins Medium 500';
  color: rgb(255, 255, 255);
  /*background: rgb(1, 155, 164);*/
  background: rgb(38, 133, 252);
  border: none;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.1em;
  display: inline-block;
  transition-duration: 0.3s;
}

.addToCart_preorder:hover {
  /*background: rgb(1, 199, 211);*/
  background: rgb(50, 140, 253);
  color: rgb(255, 255, 255);
}
