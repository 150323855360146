@media only screen and (max-width: 768px) {
	.collapsible-table th,
	.collapsible-table td {
		border-left: none;
	}

	.collapsible-table thead {
		display: none;
	}

	.collapsible-table tr {
		display: block;
		margin-bottom: 20px;
		border: 1px solid #dcdcdc;
	}

	.collapsible-table tr:last-child {
		margin-bottom: 0;
	}

	.collapsible-table tr td {
		position: relative;
	}

	.collapsible-table td {
		display: block;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px 0 0;
	}

	.collapsible-table td:before {
		content: attr(data-label);
		text-decoration: none;
		z-index: 1;
		padding: 6px;
		font-weight: bold;
		width: calc(50% - 6px);
		word-break: break-all;
	}

	.collapsible-table td:after {
		content: '';
		border-right: 1px solid #dcdcdc;
		background-color: rgb(249 250 251);
		z-index: 0;
		width: 50%;
		min-height: 100%;
		position: absolute;
	}

	.collapsible-table td:last-child {
		border-bottom: none;
	}

	.collapsible-table .order-details-total,
	.collapsible-table .order-details-product,
	.collapsible-table .order-details-discount {
		text-align: right;
		padding-left: 10px;
		width: calc(60% - 10px);
		word-break: break-word;
	}

	.collapsible-table .hidden-td {
		display: none;
	}

	.collapsible-table tfoot td {
		border-bottom: none;
	}

	.collapsible-table tfoot {
		display: block;
		margin-top: 20px;
	}

	.collapsible-table tfoot:last-child {
		border-bottom: 1px solid #dcdcdc;
	}

	.collapsible-table tfoot tr {
		margin-bottom: 0;
		border-bottom: none;
	}

	.collapsible-table tfoot tr:last-child {
		border-bottom: 1px solid #dcdcdc;
	}
}
