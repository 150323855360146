@tailwind base;
@tailwind components;
@tailwind utilities;

/* Media query example */
@media (min-width: 640px) {
  .font-oswald-desktop-only {
    font-family: 'Oswald', sans-serif;
  }
}

@layer components {
  .mobile-menu-item {
    @apply border-b-2 border-neutral-900 cursor-pointer;
    width: 100vw;
  }

  .mobile-menu-item-wrapper {
    @apply flex flex-row w-full justify-between items-center px-4 gap-3;
    min-height: 60px;
  }

  .mobile-menu-item-link {
    @apply flex items-center gap-2 w-full text-white text-xl uppercase;
    font-family: var(--font-oswald), sans-serif;
  }

  .mobile-menu-item-arrow {
    @apply flex-grow text-right w-14;
  }

  .mobile-menu-item-arrow button {
    @apply p-4;
  }

  .desktop-menu-item {
    @apply relative text-left;
    background: rgb(49, 53, 57);
  }

  .desktop-menu-item-link {
    @apply inline-flex justify-center w-full px-2 py-1 lg:py-2 lg:px-2 lg:text-lg text-white cursor-pointer uppercase tracking-widest;
    font-family: var(--font-bebas), sans-serif;
  }

  .desktop-menu-item-link:focus,
  .desktop-menu-item-link:focus-visible {
    outline: 2px solid #fff;
    outline-offset: -2px;
  }

  .desktop-menu-item-link:hover .main-submenu {
    display: flex;
    pointer-events: auto;
  }

  .desktop-menu-item svg {
    @apply -mr-1 ml-2 h-5 w-5 mt-1;
  }

  .desktop-submenu {
    @apply origin-top-right left-0 hidden pointer-events-none absolute w-56 rounded z-20 shadow-lg ring-1 ring-black py-1;
    background-color: #313539;
  }

  .desktop-menu-item:hover .desktop-submenu {
    display: block;
    pointer-events: auto;
  }

  .desktop-submenu-link {
    @apply relative text-left inline-flex w-full px-4 py-2 uppercase items-center justify-between;
    @apply text-sm focus:outline-zinc-600 hover:underline tracking-widest;
    @apply text-white transition-all duration-300 px-4 py-2 text-sm uppercase cursor-pointer;
    font-family: var(--font-oswald), sans-serif;
  }

  .desktop-submenu-item svg {
    @apply -mr-1 ml-2 h-5 w-5 mt-1;
  }

  .desktop-sub-submenu {
    @apply absolute hidden mt-2 w-56 rounded shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pointer-events-none;
    background-color: #313539;
  }

  .main-menu-item:hover .desktop-sub-submenu {
    display: block;
    pointer-events: auto;
  }
}

@import 'base.css';
@import 'buttons-add-to-cart.css';
@import 'custom.css';
@import 'account.css';
@import 'menu.css';
@import 'breadcrumbs.css';
@import './countdown-timers/sale-countdown-timer.css';
@import 'badges-pills.css';
@import 'custom-collection-image-sizes.css';

:root {
  --primary: #26292b;
  --primary-2: #f1f3f5;
  --secondary: #484b4c;
  --text-base: #000000;
  --text-primary: #000000;
  --text-secondary: white;
  --hover: rgba(0, 0, 0, 0.075);
  --hover-1: rgba(0, 0, 0, 0.15);
  --hover-2: rgba(0, 0, 0, 0.25);
  --cyan: #22b8cf;
  --green: #37b679;
  --red: #da3c3c;
  --purple: #f81ce5;
  --blue: #0070f3;
  --pink: #ff0080;
  --pink-light: #ff379c;
  --magenta: #eb367f;
  --violet: #7928ca;
  --violet-dark: #4c2889;
  --accent-0: #fff;
  --accent-1: #fafafa;
  --accent-2: #eaeaea;
  --accent-3: #999999;
  --accent-4: #888888;
  --accent-5: #666666;
  --accent-6: #444444;
  --accent-7: #333333;
  --accent-8: #111111;
  --accent-9: #000;
  --font-sans: -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica',
    sans-serif;
  --select-border: #000;
  --select-focus: #777;
  --select-arrow: var(--select-border);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@layer utilities {
  .scroll-snap-x {
    scroll-snap-type: x mandatory;
  }

  .snap-start {
    scroll-snap-align: start;
  }

  .snap-smooth {
    scroll-behavior: smooth;
  }

  .snap-px-4 {
    scroll-padding-left: 1rem;
    scroll-padding-right: 1rem;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

* {
  font-variant-ligatures: none;
}

html {
  scroll-padding-top: 0.5rem;
  scroll-behavior: smooth;
}

body {
  padding: 0 !important;
  width: 100%;
}

.no-scroll {
  overflow: hidden;
  touch-action: none;
  max-height: 100vh;
  max-height: 100dvh;
  -webkit-overflow-scrolling: touch;
}

.dh-screen {
  height: 100vh;
  height: 100dvh;
}

model-viewer::part(default-progress-mask) {
  display: none;
}

model-viewer::part(default-progress-bar) {
  display: none;
}

.gradient {
  background: linear-gradient(310deg, rgba(255, 97, 0, 1), white, rgba(0, 197, 185, 1));
  background-size: 600% 600%;
  animation: gradient-animation 14s ease infinite;

  /* prettier-ignore */
  clip-path: polygon(100% 0%, 0% 0%, 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%);
}

.rounded-none {
  border-radius: 0;
}

@keyframes gradient-animation {
  0% {
    background-position: 97% 0%;
  }
  50% {
    background-position: 4% 100%;
  }
  100% {
    background-position: 97% 0%;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: white;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

[type='search'] {
  -webkit-appearance: none;
  outline-offset: -2px;
}

.form-select::after {
  background-color: var(--select-arrow);
  box-sizing: border-box;
  content: '';
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  height: 0.5em;
  width: 0.8em;
}

.appearance-none {
  appearance: none;
}

.yellow-notice-bg {
  background-color: rgb(255, 251, 235);
  border-radius: 10px;
  border: 2px solid rgb(16, 16, 16);
}

.remove-webkit-appearance {
  -webkit-appearance: none;
}

.mobile-nav-bg {
  background: linear-gradient(180deg, #26292b 0%, #616467 100%);
}

.dark-mode .custom-radio {
  background-color: white;
  border-color: white;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f4f4f4; /* Softer background */
  border: 2px solid #ccc; /* Softer border */
  border-radius: 1.5px; /* Rounded corners */
}

.custom-checkbox:hover input ~ .checkmark {
  border-color: #bbb; /* Highlight border on hover */
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #002b4e; /* Dark background when checked */
  border-color: #002b4e; /* Matching border */
}

.custom-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
  left: 5.2px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0; /* Thicker checkmark */
  transform: rotate(35deg);
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox label {
  display: inline-block;
  margin-left: 8px;
}

input[type='radio'].custom-radio {
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid black;
  border-radius: 50%;
  outline: none;
}

input[type='radio'].custom-radio:checked {
  background-color: black;
  border: 1px solid black;
  box-shadow: inset 0 0 0 4px white;
}

.subnav-open {
  transform: translate(0, 0);
}

div[data-level='1'] > div {
  transform: translateX(0);
}

div[data-level='2'] > div {
  transform: translateX(-100vw);
}

div[data-level='3'] > div {
  transform: translateX(-200vw);
}

.ril__caption {
  z-index: 20;
  overflow: visible !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: var(--animate-duration, 1s);
  animation-fill-mode: both;
}

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: var(--animate-duration, 1s);
  animation-fill-mode: both;
}

.bg-spinner {
  --default-spinner-size: 30px;
  --default-spinner-thickness: 2px;

  position: relative;
}

.bg-spinner:before,
.bg-spinner:after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  width: var(--spinner-size, var(--default-spinner-size));
  height: var(--spinner-size, var(--default-spinner-size));
  margin-left: calc(var(--spinner-size, var(--default-spinner-size)) / -2);
  margin-top: calc(var(--spinner-size, var(--default-spinner-size)) / -2);
}

.bg-spinner:before {
  border: var(--spinner-thickness, var(--default-spinner-thickness)) solid transparent;
  border-top-color: black;
  animation: rotate 0.6s infinite linear;
  border-radius: 50%;
}

.bg-spinner:after {
  border: var(--spinner-thickness, var(--default-spinner-thickness)) solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInWhiteToBlack {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  50% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: rgba(0, 0, 0, 1);
  }
}

.slide-in-from-right {
  animation: slideInFromRight 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-from-right-with-delay {
  animation: slideInFromRight 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-from-right-with-delay:nth-child(1) {
  animation-delay: 0.1s;
}
.slide-in-from-right-with-delay:nth-child(2) {
  animation-delay: 0.2s;
}
.slide-in-from-right-with-delay:nth-child(3) {
  animation-delay: 0.35s;
}
.slide-in-from-right-with-delay:nth-child(4) {
  animation-delay: 0.5s;
}
.slide-in-from-right-with-delay:nth-child(5) {
  animation-delay: 0.65s;
}
.slide-in-from-right-with-delay:nth-child(6) {
  animation-delay: 0.8s;
}
.slide-in-from-right-with-delay:nth-child(7) {
  animation-delay: 1s;
}
.slide-in-from-right-with-delay:nth-child(8) {
  animation-delay: 1.2s;
}
.slide-in-from-right-with-delay:nth-child(9) {
  animation-delay: 1.4s;
}
.slide-in-from-right-with-delay:nth-child(10) {
  animation-delay: 1.6s;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .fade-in-white-to-black-mobile {
    animation: fadeInWhiteToBlack 0.75s ease-in-out;
    animation-fill-mode: forwards;
  }

  .fade-bg-black-to-transparent-mobile {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 45%,
      rgba(0, 0, 0, 0)
    );
  }

  .fade-bg-nearly-transparent-to-black-mobile {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 65%,
      rgb(0, 0, 0)
    );
  }

  .fade-out-mobile {
    transition: opacity 0.25s ease-in-out;
    opacity: 0 !important;
    pointer-events: none;
  }

  .fade-in-opacity-mobile {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }
}

.fade-out {
  transition: opacity 0.25s ease-in-out;
  opacity: 0 !important;
  pointer-events: none;
}

.fade-in-opacity {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.search-collection-title {
  position: relative;
  overflow: hidden;
}

.search-collection-title:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 1px solid #444;
  width: 100vw;
  margin: 0 10px;
}

.best-seller-badge {
  background: linear-gradient(240deg, #f12711 0%, #f12711 5%, #f5af19 100%);
}

.best-seller-bg {
  background: linear-gradient(240deg, #f12711 0%, #f12711 5%, #f5af19 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.search-filter-checkbox {
  position: relative;
  padding-left: 32px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.search-filter-checkbox:hover {
  outline: 0;
}

.search-filter-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.search-filter-checkbox .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fefefe;
  border: 1px solid #404040;
  border-radius: 3px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.search-filter-checkbox:hover input ~ .checkmark,
.search-filter-checkbox:hover .checkmark.checked {
  border-color: #828282;
}

.search-filter-checkbox input:checked ~ .checkmark,
.search-filter-checkbox .checkmark.checked {
  background-color: #002b4e;
  border-color: #002b4e;
}

// .search-filter-checkbox input:focus:not(:checked) ~ .checkmark,
// .search-filter-checkbox input:active:not(:checked) ~ .checkmark,
.search-filter-checkbox input:focus:not(:checked) ~ .checkmark,
.search-filter-checkbox input:active:not(:checked) ~ .checkmark {
  background-color: #dadada;
  border-color: #184b78;
}

.search-filter-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
  left: 5.2px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(35deg);
}

.search-filter-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.search-filter-checkbox .checkmark.checked:after {
  display: block;
}

.yarl__toolbar {
  top: 12px !important;
  z-index: 101;
}

.yarl__button {
  z-index: 101;
}

.yarl__icon {
  color: rgb(155, 155, 155);
}

.yarl__button.yarl__navigation_next {
  --yarl__icon_size: 50px;

  filter: none;
  padding-left: 8px;
}

.yarl__button.yarl__navigation_prev {
  --yarl__icon_size: 50px;

  filter: none;
  padding-right: 8px;
}

.yarl__button[disabled] {
  display: none;
}

// Mobile icon size
@media (min-width: 768px) {
  .yarl__icon {
    --yarl__icon_size: 50px;
  }
}

.waiting-dots span {
  animation: blink 1.4s infinite both;
  font-size: 48px;
  line-height: 0;
}

.waiting-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.waiting-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes showModalBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hideModalBackground {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal-overlay[data-state='open'] {
  animation: showModalBackground 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.modal-overlay[data-state='closed'] {
  animation: hideModalBackground 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes showModalContent {
  from {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
    --tw-translate-y: 48px;

    opacity: 0;
  }
  to {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-translate-y: 0;

    opacity: 1;
  }
}

@keyframes hideModalContent {
  from {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-translate-y: 0;

    opacity: 1;
  }
  to {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
    --tw-translate-y: 48px;

    opacity: 0;
  }
}

.modal-content[data-state='open'] {
  animation: showModalContent 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.modal-content[data-state='closed'] {
  animation: hideModalContent 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@media (min-width: 768px) {
  .modal-center-left {
    --tw-translate-x: -50%;

    left: 50%;
  }
}

.AccordionContent {
  overflow: hidden;
}
.AccordionContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
